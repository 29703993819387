<template>
  <div
    id="modal_container-success"
    class="modal_container"
    :class="{active: isDataValid}"
    @click="closeSuccessModal"
  >
    <div class="modal txt-center" @click.stop>
      <div class="close_btn h2" @click="closeSuccessModal"><span>×</span></div>
      <div class="modal_content mg-b-50">
        <p class="txt-bold">{{ modalItem.content }}</p>
      </div>
      <div class="modal_confirm">
        <button type="button" class="btn p" @click="closeSuccessModal">
          {{ modalItem.btnText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSuccess',
  props: {
    isDataValid: Boolean,
    modalItem: {},
  },
  data() {
    return {

    };
  },
  methods: {
    closeSuccessModal() {
      this.$emit('closeSuccessModal');
    },
  },
};
</script>
