<template>
  <div class="mycourses online_courses_info_exam">
    <section class="course_title">
      <div class="w1400 bg-deco">
        <img class="bg-deco-img" src="@/statics/img/index/deco_heart_r.webp" alt="">
      </div>
      <div class="w1100">
        <h3 class="title txt-bold">{{ video.name }}</h3>
        <div class="course_info">
          <p class="episode"><small>NO.{{ unitNum }}</small></p>
          <p class="course_name">{{ course.name }}</p>
          <p class="period">觀看/測驗期限: {{ computedExpiryTime }}</p>
          <div class="without_heart_btn">
            <router-link
              :to="`/register/online/mycourse/video?
              course_id=${courseID}&unit=${unitNum}&video_id=${videoID}`"
              class="btn light_green-btn"
            >
              返回觀看影片
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <div class="course_description">
      <div class="w1100">

        <div class="column_list_box">

          <div class="column_list">
            <div class="main_title h4 txt-light_green txt-bold">測驗規則</div>
            <div class="wrap">
              <div class="col100">
                <div class="content_group">
                  <div class="content txt-dark_green">
                    <p>共 {{ video.quest_count }} 題，答對 {{ video.answer_count }} 題得通過測驗</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="column_list" v-if="video.content">
            <div class="main_title h4 txt-light_green txt-bold">測驗說明</div>
            <div class="wrap">
              <div class="col100">
                <div class="content_group">
                  <div class="content txt-dark_green">
                    <p>{{ video.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="quiz-area">
      <div class="w1000">
        <div id="quiz" class="quiz">
          <ol class="quiz-list">
            <QuizList
              v-for="quiz in video.course_video_quests"
              :key="quiz.uuid"
              :quiz="quiz"
              @updateAnswer="updateAnswer"
            />
          </ol>
          <div class="btn_wrap">
            <button
              class="btn"
              @click="onSubmit"
            >送出作答</button>
          </div>
        </div>
      </div>
    </div>

    <ModalSuccess
      :isDataValid="isChooseAnswerInvalid"
      :modalItem="modalItem"
      @closeSuccessModal="closeSuccessModal"
    />
  </div>
</template>

<script>
import moment from 'moment';
import QuizList from '@/components/QuizList.vue';
import ModalSuccess from '@/components/ModalSuccess.vue';
// eslint-disable-next-line no-unused-vars
import { coursesView, courseAnswer } from '@/lib/http';
import { expiryDate } from '@/lib/public';
import { mapMutations } from 'vuex';

export default {
  name: 'RegisterOnlineMycourseExam',
  data() {
    return {
      /**
       * Static Data
       */
      courseID: '',
      videoID: '',
      unitNum: '',
      courseRule: '共 5 題，答對 3 題得通過測驗',
      course: {
        name: '乙類安寧居家療護 - 109年度社區安寧照護(乙類)醫護人員教育訓練',
        unitNumber: '01',
        unitName: '安寧療護臨床決策的倫理與困境',
        expiration: '2020/12/31 11:15',
        exam: {
          rule: '共 5 題，答對 3 題得通過測驗',
          intro: '衛生福利部中央健康保險署目前積極推動分級醫療，鼓勵醫療體系間分工、合作，使民眾獲得最好的醫療照護；健保署臺北業務組推動「共好合作」概念，期藉由各醫療院所進行上、下游垂直整合，運用健保電子轉診平台及雲端醫療資訊之上傳與分享，落實雙向轉診合作。',
        },
        quizList: [
          {
            id: 1,
            question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
            optionList: [
              {
                id: 1,
                text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
              },
              {
                id: 2,
                text: '減少併發症',
              },
              {
                id: 3,
                text: '減少醫療開銷',
              },
              {
                id: 4,
                text: '以上皆是',
              },
            ],
            chooseAnswer: '',
          },
          {
            id: 2,
            question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
            optionList: [
              {
                id: 1,
                text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
              },
              {
                id: 2,
                text: '減少併發症',
              },
              {
                id: 3,
                text: '減少醫療開銷',
              },
              {
                id: 4,
                text: '以上皆是',
              },
            ],
            chooseAnswer: '',
          },
          {
            id: 3,
            question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
            optionList: [
              {
                id: 1,
                text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
              },
              {
                id: 2,
                text: '減少併發症',
              },
              {
                id: 3,
                text: '減少醫療開銷',
              },
              {
                id: 4,
                text: '以上皆是',
              },
            ],
            chooseAnswer: '',
          },
          {
            id: 4,
            question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
            optionList: [
              {
                id: 1,
                text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
              },
              {
                id: 2,
                text: '減少併發症',
              },
              {
                id: 3,
                text: '減少醫療開銷',
              },
              {
                id: 4,
                text: '以上皆是',
              },
            ],
            chooseAnswer: '',
          },
          {
            id: 5,
            question: '有關剖腹產快速復原（ERAS）的優點，下列敘述何者正確？',
            optionList: [
              {
                id: 1,
                text: '早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院早出院',
              },
              {
                id: 2,
                text: '減少併發症',
              },
              {
                id: 3,
                text: '減少醫療開銷',
              },
              {
                id: 4,
                text: '以上皆是',
              },
            ],
            chooseAnswer: '',
          },
        ],
      },
      isChooseAnswerInvalid: false,
      modalItem: {
        content: '尚有題目未填寫',
        btnText: '確認',
      },
      answerCollection: [],
      /**
       * Axios Response Data
       */
      video: {},
      create_at: '',
      expiry_time: '',
    };
  },
  created() {
    this.courseID = this.$route.query.course_id;
    this.videoID = this.$route.query.video_id;
    this.unitNum = this.$route.query.unit;
    coursesView({
      uuid: this.courseID,
    }).then((result) => {
      this.course = result.data;

      /** 已購買 */
      if (this.course.bought_completed === false
        || this.course.bought_completed === true
      ) {
        /** 有限期 */
        if (this.course.expiry_time && this.course.expiry_time > 0) {
          /** 未過期 */
          if (moment(this.course.bought_expiry_time).isAfter()) {
            this.course.course_videos.forEach((video) => {
              if (video.uuid === this.videoID) {
                this.video = video;
              }
            });
          /** 已過期 */
          } else {
            this.assignUrlRedirect('/register/online/index');
            this.updateModalInfoContent('課程已過期，請返回課程總覽');
            this.toggleModalInfo(true);
          }
        /** 無限期 */
        } else {
          this.course.course_videos.forEach((video) => {
            if (video.uuid === this.videoID) {
              this.video = video;
            }
          });
        }
      /** 未登入、未購買 */
      } else {
        this.assignUrlRedirect('/register/online/index');
        this.updateModalInfoContent('您尚未登入或購買該課程，請返回課程總覽');
        this.toggleModalInfo(true);
      }
    });
  },
  computed: {
    computedExpiryTime() {
      /** 無期限 */
      if (!this.course.expiry_time) {
        return '無限制';
      }
      /** 課程已購買 */
      if (this.course.bought_completed === false || this.course.bought_completed === true) {
        return this.course.bought_expiry_time;
      }
      /** 課程未購買 */
      return `${this.course.expiry_days}天`;
    },
  },
  methods: {
    ...mapMutations([
      'toggleModalInfo',
      'updateModalInfoContent',
      'assignUrlRedirect',
    ]),
    onSubmit() {
      if (this.answerCollection.length === this.video.course_video_quests.length) {
        // axios call here
        // courseAnswer({
        //   video_uuid: this.videoID,
        //   answer: this.answerCollection,
        // }).then((result) => {
        //   console.log(result);
        // });
        this.$cookies.set('answerData', JSON.stringify({
          video_uuid: this.videoID,
          answer: this.answerCollection,
        }));
        this.$router
          .push({
            path: `/register/online/mycourse/result?course_id=${this.courseID}&unit=${this.unitNum}&video_id=${this.videoID}`,
          });
      } else {
        this.isChooseAnswerInvalid = true;
      }
    },
    closeSuccessModal() {
      this.isChooseAnswerInvalid = false;
    },
    updateAnswer(data) {
      this.answerCollection = this.answerCollection.filter((item) => item.uuid !== data.uuid);
      this.answerCollection.push(data);
    },
    expiryDate,
  },
  components: {
    QuizList,
    ModalSuccess,
  },
};
</script>
